import React from 'react'
import { deleteFromCart } from 'store/cart/cartReducer'
import { useDispatch } from 'react-redux'
import { Card, Col, Button } from 'react-bootstrap'


const CartMenu = ({ items }) => {

	const dispatch = useDispatch();
	const handledeleteFromCart = (e) => {
            dispatch(deleteFromCart(items.id))
    }

	return (
		<Col className='Col__products'>
			<Card className='Card__allprod'>
				<img className='Card__product-img' src={items.img} alt={items.model} />
				<Card.Body className='card-body'>
					<Card.Title as="h3">{items.model}</Card.Title>
					<Card.Text as="h5" className='item__price'>{items.price} som.</Card.Text>
					<Button variant='secondary' onClick={handledeleteFromCart}>Delete from cart</Button>
				</Card.Body>
			</Card>
		</Col>

	)
}

export default CartMenu