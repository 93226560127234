import "./App.css";
import { Routes, Route } from "react-router-dom";
import Register from "components/Layout/Register/Register";
import Login from "components/Layout/Login/Login";
import Header from "./components/Header/Header";
import Allprod from "./components/Layout/Allprod/Allprod";
import Home from "./components/Layout/Home/Home";
import Footer from "./components/Footer/Footer";
import About from "./components/Layout/About/About";
import Support from "./components/Layout/Support/Support";
import Cart from "components/Layout/Cart/Cart";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({
      once: true,
    });
  }, []);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/allprod" element={<Allprod />} />
        <Route path="/about" element={<About />} />
        <Route path="/support" element={<Support />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
