import React from 'react'
import { Link } from 'react-router-dom'
import { SignUp } from 'components/Register'
import './Register.css'

const Register = () => {
  return (
      <div className='Register__form-div'>
        <div className='Register__form'>
          <h1 className="Register__title">Registration</h1>
          <SignUp />
          <p>Already have an account?<Link className='Register__link' to='/login'>Login</Link></p>
        </div>
      </div>
  )
}

export default Register