import React, { useState } from "react";
import './Form.css'

const Form = ({ title, handleClick }) => {
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');

    return (
        <div className="Sign__form-div">
            <div className='Sign__form'>
                <input
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Email'
                    className="input__form"
                />
                <input
                    type='password'
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                    placeholder='Password'
                    className="input__form"
                />
                <button type="submit" className="custom-btn btn-13" id="button__form" onClick={() => handleClick(email, pass)}>{title}</button>
            </div>
        </div>
    )
}

export { Form }