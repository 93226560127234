import React from 'react'
import { Carousel } from 'react-bootstrap'
import './Slider.css'
import Slide1 from '../../../../sources/Slide1.png'
import Slide3 from '../../../../sources/Slide3.png'
import Slide2 from '../../../../sources/Slide2.png'

const Slider = () => {
    return (
            <Carousel className='Slider__main' variant="dark">
                <Carousel.Item className='Slider__item'>
                    <img
                        className="d-block w-100 Slider__img"
                        src={Slide1}
                        alt="First slide"
                    />
                    <Carousel.Caption className='Slider__caption'>
                        <p className='Slide__name'>The new phones are here take a look.</p>
                        <p className='Slide__text'>New IPhone 13 and 13 Pro Max. More perfomance and new design.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 Slider__img"
                        src={Slide2}
                        alt="Second slide"
                    />
                    <Carousel.Caption className='Slider__caption'>
                        <p className='Slide__name'>New, next generation Apple IPads.</p>
                        <p className='Slide__text'>Apple M1 processor. Best perfomance and speed.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 Slider__img"
                        src={Slide3}
                        alt="Third slide"
                    />
                    <Carousel.Caption className='Slider__caption'>
                        <p className='Slide__name'>Apple Watch Series 7. Best choise.</p>
                        <p className='Slide__text'>New Apple Watch Series 7. New awesome functions.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
    )
}

export default Slider