import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
	name: 'cart',
	initialState: {
		itemsInCart: [],
	},
	reducers: {
		addToCart: (state, action) => {
			state.itemsInCart.push(action.payload)
		},
		deleteFromCart: (state, action) => {
			state.itemsInCart = state.itemsInCart.filter(item => item.id !== action.payload)
		},
	},
});

export const { addToCart, deleteFromCart } = cartSlice.actions;
export default cartSlice.reducer;