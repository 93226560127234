import React from 'react'
import { Container, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Cards1 from '../../../../sources/cards1.png'
import Cards2 from '../../../../sources/cards2.png'
import Cards3 from '../../../../sources/cards3.png'
import Cards4 from '../../../../sources/cards4.png'
import './Section2.css'

const Section2 = () => {
    return (
        <Container className='Container__section2' >
            <div>
                <h2 className='Second__col-name' data-aos-delay='300' data-aos='fade-right' data-aos-duration='1200'>Save on our most selled items.</h2>
                <p className='Second__col-descb' data-aos-delay='350' data-aos='fade-up' data-aos-duration='1300'>Our new Limited Edition Winter Design BESPOKE 4-Door Flex™</p>
            </div>
            <div className="row__card">
                <div
                    className="column__card"
                    data-aos='fade-up'
                    data-aos-delay='100'
                    data-aos-duration='1200'
                >
                    <div className="col__card">
                        <p className='col__card-img'><img className='col__card-img' alt='MacBook' src={Cards1}></img></p>
                        <h3 className='col__card-name'>MacBook Pro 13 </h3>
                        <p className='col__card-descb'>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</p>
                        <p className='col__card-price'>199 990 som</p>
                        <Link to='/allprod'><Button className='col__card-button' variant="dark">More</Button></Link>                    </div>
                </div>
                <div
                    className="column__card"
                    data-aos='fade-up'
                    data-aos-delay='150'
                    data-aos-duration='1200'
                >
                    <div className="col__card">
                        <p className='col__card-img'><img className='col__card-img' alt='Galaxy Watch' src={Cards2}></img></p>
                        <h3 className='col__card-name'>Galaxy Watch 3</h3>
                        <p className='col__card-descb'>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</p>
                        <p className='col__card-price'>$ 199.00 USD</p>
                        <Link to='/allprod'><Button className='col__card-button' variant="dark">More</Button></Link>                    </div>
                </div>
                <div
                    className="column__card"
                    data-aos='fade-up'
                    data-aos-delay='200'
                    data-aos-duration='1200'
                >
                    <div className="col__card">
                        <p className='col__card-img'><img className='col__card-img' alt='MacBook' src={Cards3}></img></p>
                        <h3 className='col__card-name'>MacBook Air M1</h3>
                        <p className='col__card-descb'>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</p>
                        <p className='col__card-price'>179 000 som</p>
                        <Link to='/allprod'><Button className='col__card-button' variant="dark">More</Button></Link>                    </div>
                </div>
                <div
                    className="column__card"
                    data-aos='fade-up'
                    data-aos-delay='250'
                    data-aos-duration='1200'
                >
                    <div className="col__card">
                        <p className='col__card-img'><img className='col__card-img' alt='IPad' src={Cards4}></img></p>
                        <h3 className='col__card-name'>iPad</h3>
                        <p className='col__card-descb'>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</p>
                        <p className='col__card-price'>48 000 som</p>   
                        <Link to='/allprod'><Button className='col__card-button' variant="dark">More</Button></Link>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Section2