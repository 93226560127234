import React from 'react'
import { Button, Container } from 'react-bootstrap'
import './Section3.css'
import Cards1 from '../../../../sources/cards2-1.png'
import Cards2 from '../../../../sources/cards2-2.png'
import Cards3 from '../../../../sources/cards2-3.png'
import { Link } from 'react-router-dom'

const Section3 = () => {
    return (
        <Container>
            <h3 className='Third__col-name' data-aos='fade-right' data-aos-duration='1200'>See the best around here.</h3>
            <p className='Third__col-descb' data-aos='fade-up' data-aos-duration='1300'>Our new Limited Edition Winter Design BESPOKE 4-Door Flex™</p>
            <div className='Container__section3'>
                <div className="row__cards2">
                    <div
                        data-aos-duration='1100'
                        data-aos-delay='100'
                        data-aos='fade-up'
                        className="column__cards2"
                    >
                        <div className="card__cards2">
                            <p className='card__descb'>Smart light bulb pack</p>
                            <h3 className='card__name' id='card__name1'>Latest and gratest</h3>
                            <Link to='/allprod'> <Button className='cards__button' variant="outline-dark">Explore</Button></Link>
                            <p className='col__cards2-img'><img className='cards__img' alt='Card1' src={Cards1} width='100%'></img></p>
                        </div>
                    </div>

                    <div
                        className="column__cards2"
                        data-aos-duration='1100'
                        data-aos-delay='150'
                        data-aos='fade-up'
                    >
                        <div className="card__cards2">
                            <p className='card__descb'>Smart light bulb pack</p>
                            <h3 className='card__name' id='card__name2'>Best selling</h3>
                            <Link to='/allprod'><Button className='cards__button' variant="outline-dark">Explore</Button></Link>
                            <p className='col__cards2-img'><img className='cards__img' alt='Card2' src={Cards2} width='100%' height="auto"></img></p>
                        </div>
                    </div>

                    <div
                        data-aos-duration='1100'
                        data-aos-delay='200'
                        data-aos='fade-up'
                        className="column__cards2"
                    >
                        <div className="card__cards2">
                            <p className='card__descb'>Smart light bulb pack</p>
                            <h3 className='card__name' id='card__name3'>Every product</h3>
                            <Link to='/allprod'><Button className='cards__button' variant="outline-dark">Explore</Button></Link>
                            <p className='col__cards2-img'><img className='cards__img' alt='Card3' src={Cards3} width='100%'></img></p>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Section3