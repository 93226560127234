import React from 'react'
import './Login.css'
import { Link } from 'react-router-dom'
import { SignIn } from 'components/Login'

const Login = () => {
    return (
        <div className='Login__form-div'>
            <div className='Login__form'>
                <h1 className="Login__title">Login</h1>
                <SignIn />
                <p>Do not have account yet?<Link className='Login__link' to='/register'>Registartion</Link></p>
            </div>
        </div>
    )
}

export default Login