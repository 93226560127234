import { useState } from 'react'
import { Form } from './Form'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUser } from 'store/slices/userSlice'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const SignUp = () => {

    const styleBt = {
        color: '#fff',
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch();
    const push = useNavigate();

    const handleRegister = (email, password) => {
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
            .then(({ user }) => {
                console.log(user);
                dispatch(setUser({
                    email: user.email,
                    id: user.uid,
                    token: user.accessToken,
                }));
                push('/')
            })
            .catch(() => handleShow())
    }

    return (
        <>
            <Form
                title='REGISTER'
                handleClick={handleRegister}
            />
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>This account is already registred</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Sorry, but this E-mail is already registred. Try to log in.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}><Link to='/login' style={styleBt}>Log In</Link></Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export { SignUp }