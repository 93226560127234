import React from 'react'
import './About.css'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ItRunLogo from '../../../sources/itrun.png'
import ItRunKidsLogo from '../../../sources/itrunkids.png'

const About = () => {
  return (
    <Container className='Container__about'>
      <div><h1 className='About__title'>About Us</h1></div>
      <p className='About__text'><h3 className='About__page-support'>With the help of:</h3><img className='Itrun__logos-img' alt='ItRunLogo' src={ItRunLogo} width='256px' height='256px' /> <img className='Itrun__logos-img' alt='ItRunKidsLogo' src={ItRunKidsLogo} width='256px' height='256px' />
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere id accusamus earum dicta temporibus laboriosam officia iusto doloribus aut perferendis quam blanditiis iure fugit dolore, est a quia accusantium quae? Sunt nobis praesentium beatae hic tempora excepturi exercitationem saepe necessitatibus! Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi nemo accusamus, quibusdam mollitia dignissimos, veritatis atque provident minima minus voluptas voluptatibus aperiam? Vel blanditiis consectetur dolorum officiis illo numquam consequatur architecto, ipsam pariatur natus deleniti, provident officia a inventore repudiandae commodi expedita laborum. Velit officia odio ad ut perspiciatis! Itaque iure ad est suscipit corrupti soluta non officiis quos a.
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere id accusamus earum dicta temporibus laboriosam officia iusto doloribus aut perferendis quam blanditiis iure fugit dolore, est a quia accusantium quae? Sunt nobis praesentium beatae hic tempora excepturi exercitationem saepe necessitatibus! Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi nemo accusamus, quibusdam mollitia dignissimos, veritatis atque provident minima minus voluptas voluptatibus aperiam? Vel blanditiis consectetur dolorum officiis illo numquam consequatur architecto, ipsam pariatur natus deleniti, provident officia a inventore repudiandae commodi expedita laborum. Velit officia odio ad ut perspiciatis! Itaque iure ad est suscipit corrupti soluta non officiis quos a</p>
      <h3 className='About__page-author'>&#xa9;UmarovAzam Products</h3>
      <div className='link__home-div'>
        <Link to='/' className='link__home'><h4 className='link__home'>Click on me to go to homepage</h4></Link>
      </div>
    </Container >
  )
}

export default About