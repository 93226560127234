import { Form } from './Form.jsx'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setUser } from 'store/slices/userSlice'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const SignIn = () => {
    const dispatch = useDispatch();
    const push = useNavigate();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleLogin = (email, password) => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then(({user}) => {
                console.log(user);
                dispatch(setUser({
                    email: user.email,
                    id: user.uid,
                    token: user.accessToken,
                }));
                push('/');
            })
            .catch(() => handleShow())
    }
    return (
        <>
            <Form
                title='LOGIN'
                handleClick={handleLogin}
            />
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Invalid E-mail or password. Try again.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        You entered wrong E-mail or password, please check and try again.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>Try again</Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export { SignIn }