import React from 'react'
import { Container, Row, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import CartMenu from './CartMenu/CartMenu'
import { useAuth } from 'hooks/use-auth'
import { Link } from 'react-router-dom'
import './Cart.css'

const Cart = () => {

	const { isAuth } = useAuth();
	const items = useSelector(state => state.cart.itemsInCart)
	const totalPrice = items.reduce((acc, items) => acc += items.price, 0)


	return (
		<>
			{isAuth ?
				<Container className='Cart__container'>
					<h1 className="Cart__title">Your cart</h1>
					{
						items.length > 0 ?
							<div className='Totalprice__cart'>
								<h2>Total:</h2>
								<h3 className='Totalprice__cart-h3'>{totalPrice} som.</h3>
							</div>
							: <div className='Totalprice__cart-div' ><h3 className='Totalprice__cart-h3'>Cart is empty ¯\_(ツ)_/¯</h3></div>
					}
					<Row xs={2} md={4} className="g-3 ">
						{
							items.map(items => <CartMenu items={items} key={items.id} />)
						}
					</Row>
					{ items.length > 0 && <Button variant='primary' id='order__cart' type='submit'><Link to='/'>Order</Link></Button>}
				</Container>
				: <Container className='Cart__container'>
					<h1>First you need to <Link className='Cart__redirect' to='/login'>Login</Link> or <Link className='Cart__redirect' to='/register'>Register</Link></h1>
				</Container>
			}
		</>
	)
}

export default Cart