import './Section1.css'
import React from 'react'
import { Container } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import Tabimg1 from '../../../../sources/tabimg1.png'
import Tabimg2 from '../../../../sources/tabimg2.png'
import Tabimg4 from '../../../../sources/tabimg4.png'
import Tabimg3 from '../../../../sources/tabimg3.png'
import Tabimg1mobile from '../../../../sources/tabimg1mobile.png'
import Tabimg2mobile from '../../../../sources/tabimg2mobile.png'
import Tabimg3mobile from '../../../../sources/tabimg3mobile.png'

const Section1 = () => {
  return (
    <div>
      <Container>
        <div className='Container__main'>
          <div className='text__main'>
            <h2 className='First__row-name' data-aos='fade-right' data-aos-delay='200' data-aos-duration='1200'>Shop our latest offers and categories.</h2>
            <p className='First__row-descb' data-aos='fade-up' data-aos-delay='250' data-aos-duration='1300'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Scelerisque in est dui, aliquam, tempor. Faucibus morbi turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
        </div>
      </Container>
      <div className='col-6__first'>
        <div
          className='col-6__row'
          data-aos='fade-right'
          data-aos-delay='100'
          data-aos-duration='1300'
        >
          <div className='row__1-top'><img id='row__1-top__img' src={Tabimg1} alt='tab1'></img><span className='row__1-top__sm'>Laptops</span><p className='row__1-top__text'>True Laptop Solution</p></div>
          <div className='row__1-bottom'><img id='row__1-bottom__img' src={Tabimg2} alt='tab2'></img><p className='row__1-bottom__text'><p className='row__1-bottom__small'>Watch</p>Not just<br /> stylish</p></div>
        </div>
        <div className='col-6__row-2'>
          <div
            className='row__1-left'
            data-aos='fade-down'
            data-aos-delay='150'
            data-aos-duration='1300'
          >
           <img id='row__1-left__img' src={Tabimg3} alt='tab3'></img><p className='row__1-left__small'>Phones</p><p className='row__1-left__text'>Your day to day life</p></div>
          <div
            className='row__1-right'
            data-aos='fade-up'
            data-aos-delay='200'
            data-aos-duration='1300'
          >
            <p className='row__1-right__small'>Tablet</p><p className='row__1-right__text'>Empower your work</p><img id='row__1-right__img' src={Tabimg4} alt='tab4'></img></div>
        </div>
      </div>
      <div className='col-12__mobile'>
        <Container className='Tabs__mobile'>
          <Card bg='white' className="text-dark Tabs__mobile" data-aos='fade-up' data-aos-duration='1000'>
            <img className='col-12__mobile_img1' alt='tab1mobile' src={Tabimg1mobile}></img>
            <Card.Text className='col-12__mobile-small1'>
              Phones
            </Card.Text>
            <Card.Title className='col-12__mobile-text1'>Your day to day life</Card.Title>
          </Card>
          <Card bg='white' className="text-dark Tabs__mobile" data-aos='fade-up' data-aos-duration='1000'>
            <img className='col-12__mobile_img2' alt='tab2mobile' src={Tabimg2mobile}></img>
            <Card.Text className='col-12__mobile-small1'>
              Laptops
            </Card.Text>
            <Card.Title className='col-12__mobile-text2'>True Laptop Solution</Card.Title>
          </Card>
          <Card bg='white' className="text-dark Tabs__mobile" data-aos='fade-up' data-aos-duration='1000'>
            <img className='col-12__mobile_img3' alt='tab3mobile' src={Tabimg3mobile}></img>
            <Card.Text className='col-12__mobile-small1'>
              Watch
            </Card.Text>
            <Card.Title className='col-12__mobile-text3'>Not just stylish</Card.Title>
          </Card>
        </Container>
      </div>
    </div>
  )
}

export default Section1