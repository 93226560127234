import React, { useState } from 'react'
import imgLogo from '../../sources/Logo.svg';
import allHead from '../../sources/devicesHead.svg'
import infoHead from '../../sources/infoHead.svg'
import supportHead from '../../sources/supportHead.svg'
import cartHead from '../../sources/cartHead.png'
import SignUpIcon from '../../sources/signup.png'
import SignInIcon from '../../sources/signin.png'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from 'hooks/use-auth';
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeUser } from 'store/slices/userSlice';
import './Header.css';


const Header = () => {
    const dispatch = useDispatch();
    const push = useNavigate();

    const { isAuth, email } = useAuth();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const LogOut = () => { dispatch(removeUser()) }

    const items = useSelector(state => state.cart.itemsInCart)
	const totalPrice = items.reduce((acc, items) => acc += items.price, 0)

    return (
        <Navbar className='Header__nav' expand="lg">
            <Container id='cont__head'>
                <Navbar.Brand> <Link to='/'><img alt='Logo' className='Logo__head' src={imgLogo}></img></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        style={{ maxHeight: '200px' }}
                        navbarScroll
                    >
                        <Nav.Link as='div'><Link to='/allprod' className='Link__head'><img className='Icons__header' src={allHead} alt='AllIcon'/>All Products</Link></Nav.Link>
                        <Nav.Link as='div'><Link to='/about' className='Link__head'><img className='Icons__header' src={infoHead} alt='InfoIcon'/>About Us</Link></Nav.Link>
                        <Nav.Link as='div'><Link to='/support' className='Link__head'><img className='Icons__header' src={supportHead} alt='SupportIcon'/>Support</Link></Nav.Link> 
                        {
                            isAuth ?
                                <div className='Email__head'>
                                    <Nav.Item className='Email-head'>{email}</Nav.Item>
                                    <Button variant="primary" onClick={handleShow} id='logout__btn'>Log out</Button>
                                </div> :
                                <>
                                    <Nav.Link as='div'><Link to='/register' className='Link__head Register__btn'><img className='Icons__header' src={SignUpIcon} alt='CartIcon'/>Register</Link></Nav.Link>
                                    <Nav.Link as='div'><Link to='/login' className='Link__head Login__btn'><img className='Icons__header' src={SignInIcon} alt='CartIcon'/>Log In</Link></Nav.Link>
                                </>
                        }
                        {isAuth ? <Nav.Link as='div' className='Cart__div'><Link to='/cart' className='Link__head Cart__text'><img className='Cart__ico' src={cartHead} alt='CartIcon'/> || { totalPrice } som.</Link></Nav.Link>:null}
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Log Out</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button variant="primary" onClick={() => { LogOut(); handleClose(); push('/login'); }}>
                                    Yes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header