import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import './Support.css'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { useAuth } from 'hooks/use-auth'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'


const Support = () => {
  const [show, setShow] = useState(false);
  const { isAuth, email } = useAuth();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    register,
    handleSubmit } = useForm()

  const userQuestions = (data) => {
    axios.post(` http://localhost:8080/questions`, { ...data })
      .then((res) => {
        handleShow()
      })
  }
  return (
    <>
      {isAuth ?
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Success</Modal.Title>
            </Modal.Header>
            <Modal.Body>Thanks for your question! We will answer you as soon as possible! Check your e-mail.</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <section>
            <div className='Support'>
              <form className='support__form' onSubmit={handleSubmit(userQuestions)}>
                <h1 id='Support__form-title'>Ask your question</h1>
                <p id='email' className='input__support' {...register('email')} type='email' placeholder=' Your Email' required>{email}</p>
                <input id='theme' className='input__support' {...register('theme')} type='text' placeholder='Theme of your question' required></input>
                <textarea id='textarea' className='textarea__support' {...register('question')} placeholder='Your question...' required></textarea>
                <button class="custom-btn btn-13" type='submit'>Submit</button>
              </form>
            </div>
          </section>
          <section>
            <Container>
              <div className='contacts__support'>
                <h1 className='contacts__support-title'>Or contact us</h1>
              </div>
              <div className='contacts__support-textdiv'>
                <p className='contacts__support-text'>Telegram: @umarov2807</p>
                <p className='contacts__support-text'>Phone & WhatsApp: +996 (552) 531 101</p>
                <p className='contacts__support-text'>Email: azamumarov182@gmail.com</p>
              </div>
            </Container>
          </section>
        </> :
        <Container className='Redirect__support'>
          <h1>First you need to <Link className='Cart__redirect' to='/login'>Login</Link> or <Link className='Cart__redirect' to='/register'>Register</Link></h1>
        </Container>
      }
    </>
  )
}

export default Support