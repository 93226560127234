import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { addToCart } from 'store/cart/cartReducer';
import { deleteFromCart } from 'store/cart/cartReducer';
import { Button } from 'react-bootstrap';
import { useAuth } from 'hooks/use-auth';
import './Products.css'
import { Link } from 'react-router-dom';


const AllItems = ({ item }) => {

    const { isAuth } = useAuth();
    const dispatch = useDispatch();

    const handleAddToCart = (e) => {
        if (isItemInCart) {
            dispatch(deleteFromCart(item.id))
        } else {
            dispatch(addToCart(item))
        }
    }

    const items = useSelector(state => state.cart.itemsInCart)
    const isItemInCart = items.some(items => items.id === item.id)

    return (
        <Col className='Col__products'>
            <Card className='Card__allprod'>
                <img className='Card__product-img' src={item.img} alt={item.model} />
                <Card.Body className='card-body'>
                    <Card.Title as="h3">{item.model}</Card.Title>
                    <Card.Text>
                        {item.description}
                    </Card.Text>
                    <Card.Text as="h5" className='item__price'>{item.price} som.</Card.Text>
                    {isAuth ? isItemInCart ? <Button variant='secondary' onClick={handleAddToCart}>Delete from Cart</Button> : <Button variant='primary' onClick={handleAddToCart}>Buy</Button> : <Button variant='primary'><Link id='Allprod__login-redir' to='/login'>Buy</Link></Button>}
                </Card.Body>
            </Card>
        </Col>
    )
}

export default AllItems