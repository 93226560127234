import React from 'react'
import { Container } from 'react-bootstrap'
import './Section4.css'

const Section4 = () => {
  return (
    <div className='Section4__container'>
      <Container>
        <h2 className='About__home-title' data-aos-delay='300' data-aos='fade-right' data-aos-duration='600'>About Us</h2>
        <p data-aos='fade-right' data-aos-delay='350' data-aos-duration='800'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere id accusamus earum dicta temporibus laboriosam officia iusto doloribus aut perferendis quam blanditiis iure fugit dolore, est a quia accusantium quae? Sunt nobis praesentium beatae hic tempora excepturi exercitationem saepe necessitatibus! Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi nemo accusamus, quibusdam mollitia dignissimos, veritatis atque provident minima minus voluptas voluptatibus aperiam? Vel blanditiis consectetur dolorum officiis illo numquam consequatur architecto, ipsam pariatur natus deleniti, provident officia a inventore repudiandae commodi expedita laborum. Velit officia odio ad ut perspiciatis! Itaque iure ad est suscipit corrupti soluta non officiis quos a.</p>
        <h3 className='About__home-author pouring' data-aos-delay='400' data-aos='fade-up' data-aos-duration='1000'><span className='pouring__text'>&#xa9;UmarovAzam Products</span></h3>
      </Container>
    </div>
  )
}

export default Section4