import React from 'react'
import { useAuth } from 'hooks/use-auth';
import imgLogoLight from '../../sources/LogoLight.svg'
import ReactIco from '../../sources/react.png'
import HomeIcoWhite from '../../sources/homeWhite.png'
import AboutIcoWhite from '../../sources/aboutWhite.png'
import SupportIcoWhite from '../../sources/supportWhite.png'
import AllIcoWhite from '../../sources/allWhite.png'
import CartIcoWhite from '../../sources/cartWhite.png'
import InstaIcon from '../../sources/instagram.png'
import TelegramIcon from '../../sources/telegram.png'
import VkIcon from '../../sources/vk.png'
import WhatsappIcon from '../../sources/whatsapp.png'
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {

    const { isAuth } = useAuth();

    return (
        <footer className="footer-bs">
            <div className="row">
                <div className="col-md-4 footer-brand">
                    <Link to='/'><img alt='Logo' className='Logo__foot' src={imgLogoLight}></img></Link>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere id accusamus earum dicta temporibus laboriosam officia iusto doloribus aut perferendis quam blanditiis iure fugit dolore, est a quia accusantium quae?</p>
                    <p>&#xa9;2023 «Californiashop.com».</p>
                    <p>Powered by React JS<img alt='ReactIcon' src={ReactIco} /></p>
                </div>

                <div className="col-md-3 footer-nav">
                    <h4>Menu</h4>
                    <div className="col-md-3">
                        <ul className="pages">
                            <li><Link to='/'><img className='Footer__icon' src={HomeIcoWhite} alt='HomeIcon' />Home</Link></li>
                            <li><Link to='/about'><img className='Footer__icon' src={AboutIcoWhite} alt='AboutIcon' />About Us</Link></li>
                            <li><Link to='/support'><img className='Footer__icon' src={SupportIcoWhite} alt='SupportIcon' />Support</Link></li>
                            <li><Link to='/allprod'><img className='Footer__icon' src={AllIcoWhite} alt='AllProdIcon' />All Products</Link></li>
                            {isAuth && <li><Link to='/cart'><img className='Footer__icon' src={CartIcoWhite} alt='CartIcon' />My Cart</Link></li>}
                        </ul>
                    </div>
                </div>
                <div className="col-md-2 footer-social">
                    <h4>Social</h4>
                    <ul>
                        <li><a target="_blank" rel="noreferrer" href="https://vk.com/ac10100"><img className='Footer__icon-social' src={VkIcon} alt='VK'/>VKontakte</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://t.me/umarov2807"><img className='Footer__icon-social' src={TelegramIcon} alt='Telegram' />Telegram</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/_.umarov._28/"><img className='Footer__icon-social' src={InstaIcon} alt='Instagram'/>Instagram</a></li>
                        <li><p><img className='Footer__icon-social' src={WhatsappIcon} alt='WhatsApp' />WhatsApp: +996(552)-531-101</p></li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer