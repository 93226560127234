import React, { useEffect, useState } from 'react'
import './Allprod.css'
import axios from 'axios'
import AllItems from './Products/Products';
import Loader from 'components/Layout/Loader/Loader';
import Clear from 'sources/close.svg'
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';

const Allprod = () => {

  const handleSetType = (e) => {
    setType(e.target.value)
  }

  const handleSetSearch = (e) => {
    setSearch(e.target.value)
  }

  const ClearAll = (e) => {
    setType('');
    setSearch('')
  }

  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [type, setType] = useState('')
  const [item, setItem] = useState([])

  useEffect((item) => {
    axios.get(`https://63ac4406da81ba97617f073c.mockapi.io/devices`)
      .then((res) => {
        setItem(res.data);
        setLoading(false)
      })

  }, []);

  return (
    <>
      <Container className='Container__allprod'>
        <h1 className='Search__text'>{search ? `Search results for: "${search}"` : "All Products"}</h1>
        <div className='Search__block'>
          {search && <img onClick={(e) => setSearch('')} className='Clear__search' src={Clear} alt='clear cu-p' />}
          <input onChange={handleSetSearch} value={search} type='text' className='Search__input' placeholder='Search...' />
          <div className='radio__block wrapper'>
            <input onClick={ClearAll} type='radio' value='' className='Search__radio' id="radio-all" />
            <label for='radio-all'>All</label>
            <input onClick={handleSetType} type='radio' value='phone' className='Search__radio' id="radio-phones" />
            <label for='radio-phones'>Phones</label>
            <input onClick={handleSetType} type='radio' value='laptop' className='Search__radio' id="radio-laptops" />
            <label for='radio-laptops'>Laptops</label>
            <input onClick={handleSetType} type='radio' value='tablet' className='Search__radio' id="radio-tablets" />
            <label for='radio-tablets'>Tablets</label>
            <input onClick={handleSetType} type='radio' value='watch' className='Search__radio' id="radio-watches" />
            <label for='radio-watches'>Watches</label>
          </div>
        </div>
        <Row xs={2} md={4} className="g-3">
          {
            loading ? <Loader /> :
              item.filter((item) => item.model.toLowerCase().includes(search.toLowerCase())).filter((item) => item.type.includes(type)).map(item => <AllItems className='Products' key={item.id} item={item} />)
          }
        </Row>
      </Container>
    </>
  )
}

export default Allprod